/*
 *   Access to tenant specific data anywhere in the app.
 *
 *   Installation (main.ts):
 *       import { createPinia, PiniaVuePlugin } from 'pinia';
 *       Vue.use(PiniaVuePlugin);
 *       const pinia = createPinia();
 *       new Vue({
 *          // other options...
 *          pinia
 *       })
 *
 *   Usage:
 *       Component setup (options api):
 *          import { useTenantStore } from '../stores/tenant.ts';
 *          import { mapStores } from 'pinia';
 *
 *          computed: {
 *             ...mapStores(useTenantStore)
 *          },
 *
 *       Component setup (composition api):
 *          import { useTenantStore } from '../stores/tenant.ts';
 *
 *          setup() {
 *             const tenantStore = useTenantStore();
 *
 *             return { tenantStore };
 *          }
 *
 *       To update the values (E.g. Currency):
 *          this.tenantStore.setTenantDetails({ ..., currencyCode: 'AUD' });
 *
 *       To read the values (E.g. Currency):
 *          this.tenantStore.currencyCode
 *
 *   Legacy note:
 *       To update the name and tag keys from a v1 Tenant data structure
 *       (E.g. from the "me" profile):
 *          this.tenantStore.setTenantDetailsFromV1(this.me.Tenant);
 */
const LAST_TENANT_KEY = "cc-last-tenant";
import ApiV2 from "@/lib/ApiV2";
import AuthClient from "@/lib/Auth";
import Config from "@/lib/Config";
import { defineStore } from "pinia";

export interface IActiveImport {
    cloudAccountId: string;
    month: number;
    year: number;
}

export interface IBasicCloudAccount {
    name: string;
    type: string;
}

export interface ITenantData {
    tenantId: string;
    tenantName: string;
    currencyCode: string;
    tagKey1?: string;
    tagKey2?: string;
    tagKey3?: string;
    customerId: string;
    numberOfActiveAccounts: number;
    lastImportStartedDate?: string;
    lastImportCompletedDate?: string;
    lastUsageDateForTenant?: string;
    features: string[];
    permissions: string[];
    isSelfService: boolean;
    hasActiveSubscription: boolean;
    status: {
        hasActiveImports: boolean;
        activeUserAlerts: Array<{
            id: number;
            level: string;
            alertText: string;
        }>;
    };
    dimensions?: { [index:number]: string };
    activeImports?: IActiveImport[];
    cloudAccounts?: { [id:string]: IBasicCloudAccount }
}

export const useDatasetState = (
    tenantStore: any,
): { hasUsageDataset: boolean; hasEmissionsDataset: boolean } => {
    const hasGranularTogglesFeature =
        Config.isFeatureEnabled("DatasetFilter") ||
        tenantStore.hasTenantFeature("DatasetFilter");
    const hasUsageDataset = Config.isFeatureEnabled("Dataset.Usage") ||
        tenantStore.hasTenantFeature("Dataset.Usage") ||
        !hasGranularTogglesFeature;
    const hasEmissionsDataset = Config.isFeatureEnabled("Dataset.Emissions") ||
        tenantStore.hasTenantFeature("Dataset.Emissions") ||
        tenantStore.hasTenantFeature("EmissionsAndEnergy") ||
        !hasGranularTogglesFeature;
    return { hasEmissionsDataset, hasUsageDataset };
};

export const useTenantStore = defineStore({
    id: "tenant",
    state: (): ITenantData => {
        return getInitialTenant();
    },
    getters: {
        hasTenantFeature(state) {
            return (feature: string) => {
                if (!Config.isFeatureEnabled("EnableTenantFeatures")) {
                    return true;
                }
                return (state.features || []).includes(feature);
            };
        },
        hasPermission(state) {
            return (permission: string) =>
                (state.permissions || []).includes(permission);
        },
    },
    actions: {
        async changeTenantContext(tenantId: string) {
            await this.loadTenant(tenantId);
        },
        async loadTenant(tenantId?: string) {
            if (tenantId) AuthClient.tenantId = tenantId;
            const tenantSummary = await ApiV2.http.get(
                "/api/metadata/tenants/current/summary",
            ).then((r) => r.data);
            if (tenantSummary) {
                this.setTenantDetails(tenantSummary);
            }
        },
        setTenantDetails(data: Partial<ITenantData>) {
            this.$patch(data);
            const newTenantString = JSON.stringify(this.$state);
            localStorage.setItem(LAST_TENANT_KEY, newTenantString);
        },
        setTenantDetailsFromV1(data: { name: string; tagHeirarchy: string[] }) {
            const newTenantData = {
                name: data.name,
                tagKey1: data.tagHeirarchy[0] || undefined, // empty strings and nulls -> undefined
                tagKey2: data.tagHeirarchy[1] || undefined,
                tagKey3: data.tagHeirarchy[2] || undefined,
            };
            this.setTenantDetails(newTenantData);
        },
        getHierarchy(): string[] {
            const tags = [this.tagKey1, this.tagKey2, this.tagKey3];
            if (!tags.find((t) => t)) return [];
            return tags;
        },
    },
});

const getInitialTenant = (): ITenantData => {
    const initialTenantString = localStorage.getItem(LAST_TENANT_KEY);
    let initialTenant = JSON.parse(initialTenantString) as ITenantData;
    if (!initialTenant) {
        initialTenant = <ITenantData> {
            tenantId: "",
            tenantName: "",
            currencyCode: "AUD",
            tagKey1: null,
            tagKey2: null,
            tagKey3: null,
            customerId: "",
            numberOfActiveAccounts: 0,
            lastImportStartedDate: null,
            lastImportCompletedDate: null,
            lastUsageDateForTenant: null,
            features: [],
            permissions: [],
            isSelfService: false,
            hasActiveSubscription: false,
            status: {
                hasActiveImports: false,
                activeUserAlerts: [],
            },
        };
    }
    return initialTenant;
};
