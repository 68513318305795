
import { getTagUsage, getAllTagKeys, getSpend } from '@/lib/Api';
import moment from '@/lib/moment';
import TagSpendTable, {
    convertSpendByTagResultToDimensions,
    convertSpendByTagResultToItems,
} from '@/components/Domain.Tags/TagSpendTable.vue';
import TagSpendAreaChart, { convertTagSpendResponseToDatasets } from '@/components/Domain.Tags/TagSpendAreaChart.vue';
import { waitFor } from '@/plugins/vue-wait';
import { toastError } from '@/components/Common/Toast.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { ISpendByTagResult, Granularity, SpendFieldNames, PluralEntityType } from '@/models';
import {
    SpendUsageOptions,
    convertSpendOptionsToQuery,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';

interface ITagOverviewParamsViewModel {
    granularity: Granularity.daily | Granularity.monthly;
    min: Date;
    max: Date;
    dimensions: string[];
}

export default defineComponent({
    components: {
        TagSpendAreaChart,
        SpendUsageTable
    },
    title(ctx) {
        return ctx.$t('tagsOverview.title');
    },
    data() {
        const usageOptions: SpendUsageOptions = {
            dateRangeKey: '3m',
        };
        return {
            filterPanels: 0,
            primaryTag: null,
            additionalTags: [],
            tagKeys: [],
            startDate: moment().subtract(6, 'months'),
            startMenu: false,
            endDate: moment(),
            endMenu: false,
            tagTableItems: [],
            tagTableDimensions: [],
            tagChartItems: { labels: [], datasets: [] },
            valid: false,
            periodRules: [(v) => !!v || 'Date period is required'],
            startDateRules: [
                () => {
                    const startDate = moment.utc((this as any).startDate).startOf('month');
                    const endDate = moment.utc((this as any).endDate).endOf('month');
                    if (startDate.isBefore(endDate)) return true;
                    else return 'Start date must be before or equal to end date';
                },
            ],
            endDateRules: [
                () => {
                    const startDate = moment.utc((this as any).startDate).startOf('month');
                    const endDate = moment.utc((this as any).endDate).endOf('month');
                    if (startDate.isBefore(endDate)) return true;
                    else return 'End date must be after or equal to start date';
                },
            ],
            primaryTagRules: [(v) => !!v || 'Primary tag is required'],
            additionalTagRules: [(v) => v.length < 3 || 'Max 2 additional tags supported'],
            usageOptions,
            usageTableItems: null,
        };
    },
    async mounted() {
        this.getTagKeys();
    },
    computed: {
        params(): ITagOverviewParamsViewModel {
            const tags: string[] = [];
            tags.push(this.primaryTag);
            if (this.additionalTags && this.additionalTags.length > 0) {
                tags.push(this.additionalTags);
            }
            return {
                granularity: Granularity.monthly,
                min: moment.utc(this.startDate, "MMMM 'YY").startOf('month').toDate(),
                max: moment.utc(this.endDate, "MMMM 'YY").endOf('month').toDate(),
                dimensions: tags,
            };
        },
        segregatedBy() {
            return this.params.dimensions.filter(d => d).map((d) => `tags/${d}`);
        },
        ...mapStores(useTenantStore),
    },
    methods: {
        getTagKeys: waitFor('loadingTagKeys', async function loadTagKeys() {
            try {
                this.tagKeys = await getAllTagKeys().then((k) => k.data);
            } catch (err) {
                toastError('There was an issue loading the Tag Keys.');
                this.tagKeys = [];
            }
        }),
        getTagSpendData: waitFor('loadingTagSpend', async function loadTagSpend() {
            try {
                this.filterPanels = null;
                const params = convertSpendOptionsToQuery(this.usageOptions as SpendUsageOptions);
                if (!params) return;

                const usageResponse = await getSpend({
                    ...params,
                    segregateBy: this.segregatedBy,
                    fields: [SpendFieldNames.Charges],
                });

                this.usageTableItems = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    this.segregatedBy,
                    usageResponse,
                );
                this.tagChartItems = convertTagSpendResponseToDatasets(usageResponse);

            } catch (err) {
                toastError('There was an issue loading the data.');
                this.tagTableItems = [];
                this.tagTableDimensions = [];
                this.tagChartItems = [];
                this.filterPanels = 0;
            }
        }),
        remove(item) {
            const index = this.additionalTags.indexOf(item);
            if (index >= 0) this.additionalTags.splice(index, 1);
        },
        handleDialogChange(isOpen) {
            if (isOpen) return;
            this.$router.push('/tagoverview');
            this.$title = this.$t('tagsOverview.title');
        },
    },
});
