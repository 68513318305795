
import { useTenantStore, useWaitStore, storeToRefs, useFilterStore } from '@/stores';
import SpendUsageBarChart, {
    convertProjectionsResponseToDataset,
    convertUsageResponseToDatasets,
    IClickEvent,
    UsageReportTypes,
} from '@/components/Domain.Usage/SpendUsageBarChart.vue';
import SpendUsageOptionsToolbarForm, {
    SpendUsageOptions,
    CostView,
    convertSpendOptionsToQuery,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import UsageFilterChips, {
    filterUsageParamsByFilterChips,
    IChipViewModel,
} from '@/components/Domain.Usage/UsageFilterChips.vue';
import SpendUsageTable from '@/components/Domain.Usage/SpendUsageTable.vue';
import { toastError } from '@/components/Common/Toast.vue';
import { defineComponent, ref, watch } from 'vue';
import { getUsage, getForecast } from '@/lib/Api';
import { fromPlural } from '@/models/EntityType';
import { EntityType, PluralEntityType, IGeographyModel, ISpendUsageTableRecordsViewModel } from '@/models';
import { i18n } from '@/i18n';
import { SpendUsageTableFunctions } from '@/components/Domain.Usage/SpendUsageTableFunctions';
export default defineComponent({
    components: {
        SpendUsageOptionsToolbarForm,
        UsageFilterChips,
        SpendUsageTable,
        SpendUsageBarChart,
    },
    props: { value: Object, loading: Boolean },
    setup(p) {
        const { currencyCode } = storeToRefs(useTenantStore());
        const { is, whilst } = useWaitStore();
        const filterStore = useFilterStore();
        filterStore.setFilter({segregateBy: PluralEntityType.regions});
        const spendOptions = ref<SpendUsageOptions>(filterStore.options);
        const newChartData = ref({ labels: [], datasets: [] });
        const usageTableItems = ref<ISpendUsageTableRecordsViewModel | null>(null);
        const filters = ref<IChipViewModel[]>([]);
        const searchSupportedTypes = ref<EntityType[]>([EntityType.service, EntityType.subscription, EntityType.tag]);
        const segregationSupportedKeys = ref<PluralEntityType[]>([
            PluralEntityType.regions,
            PluralEntityType.services,
            PluralEntityType.meters,
        ]);

        const loadTabUsage = async () =>
            await whilst('getGeographyUsage', async () => {
                const { geographyName, providerType } = p.value as IGeographyModel;
                const options: SpendUsageOptions = { ...spendOptions.value, geographyName, providerType };
                if (Array.isArray(options.segregateBy)) throw new Error('Multiple segregations unsupported!');
                const segregateBy = options.segregateBy as PluralEntityType;
                let params = convertSpendOptionsToQuery(options);
                params = filterUsageParamsByFilterChips(filters.value, params);
                if (!params) return;
                const [usageResponse, projectionsResponse] = await Promise.all([
                    getUsage(segregateBy, params),
                    getForecast(params, options.dateRangeKey),
                ]);
                const usageDataset = convertUsageResponseToDatasets(usageResponse, {
                    reportType: UsageReportTypes.charges,
                });
                const forecastDataset = convertProjectionsResponseToDataset(projectionsResponse);
                newChartData.value = {
                    labels: [...forecastDataset.labels, ...usageDataset.labels],
                    datasets: [...forecastDataset.datasets, ...usageDataset.datasets],
                };
                usageTableItems.value = SpendUsageTableFunctions.convertToItemRecords(
                    params,
                    segregateBy,
                    usageResponse,
                    options.costView
                );
            });

        const handleDatasetClicked = ({ isOther, name, datasetGroupId }: IClickEvent) => {
            if (isOther) {
                spendOptions.value.topXResults = spendOptions.value.topXResults + 10;
            } else if (spendOptions.value.segregateBy === PluralEntityType.meters) {
                toastError(i18n.t('shared.meterInformationNotSupported'));
            } else {
                filters.value.push({
                    id: datasetGroupId,
                    name,
                    type: fromPlural(spendOptions.value.segregateBy) as EntityType,
                });
            }
        };

        watch(
            () => [p.value, spendOptions.value, filters.value],
            () => {
                if (!p.value || !spendOptions.value || is('getGeographyUsage')) return;
                loadTabUsage();
            },
            { immediate: true, deep: true }
        );

        return {
            spendOptions,
            newChartData,
            usageTableItems,
            filters,
            searchSupportedTypes,
            segregationSupportedKeys,
            loadTabUsage,
            handleDatasetClicked,
            currencyCode,
            wait: { is },
        };
    },
});
