
import { waitFor } from '@/plugins/vue-wait';
import CarbonBarChart, {
    convertCO2eResponseToDatasets,
    convertProjectionsResponseToDataset,
} from '@/components/Domain.Carbon/CarbonBarChart.vue';
import CarbonTable, { convertToItemRecords } from '@/components/Domain.Carbon/CarbonTable.vue';
import SpendUsageOptionsToolbarForm, {
    SpendUsageOptions,
    convertSpendOptionsToQuery,
    calculateOptionsDescription,
} from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { useTenantStore } from '@/stores/tenant';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { Granularity, PluralEntityType } from '@/models';
import { getCO2e, getForecastCO2e } from '@/lib/Api';
import { setUserPreferences, getUserPreferences } from '@/lib/UserPreferences';

const getSegregateByFromRoute = (route) => {
    let path = route.path.toLowerCase();
    if (route.params.baseTenantId) {
        path = path
            .split('/')
            .filter((x) => x != route.params.baseTenantId)
            .join('/');
    }
    if (path.startsWith('/emissions/explore/services')) {
        return 'services';
    } else if (path.startsWith('/emissions/explore/subscriptions')) {
        return 'subscriptions';
    } else if (path.startsWith('/emissions/explore/cloudaccounts')) {
        return 'cloudaccounts';
    } else if (path.startsWith('/emissions/explore/products')) {
        return 'products';
    } else if (path.startsWith('/emissions/explore/tagkeys')) {
        return 'tags';
    } else if (path.startsWith('/emissions/explore/productcategories')) {
        return 'productcategories';
    } else {
        return 'subscriptions';
    }
};

export default defineComponent({
    components: { CarbonBarChart, SpendUsageOptionsToolbarForm, CarbonTable },
    title(ctx) {
        return ctx.$t('emissionsExplorer.title');
    },
    data() {
        return {
            tab: null,
            tagKey: this.$route.params.tagkey,
            carbonOptions: {
                dateRangeKey: '60d',
                granularity: Granularity.daily,
                topXResults: 25,
                segregateBy: getSegregateByFromRoute(this.$route),
            },
            carbonRecords: {},
            groupingItems: {},
            datasetIndexes: {},
            newChartData: { labels: [], datasets: [] },
            starred: false,
        };
    },
    watch: {
        carbonOptions: {
            handler(options) {
                if (!options) return;
                if (this.$wait.is('gettingCarbonRecords')) return;
                this.getCarbonRecords(options);
            },
            immediate: true,
            deep: true,
        },
        $route: {
            async handler(route) {
                if (!route) return;
                this.tagKey = route.params.tagkey;
                const newSegregateBy = getSegregateByFromRoute(route);
                if (this.carbonOptions.segregateBy !== newSegregateBy) {
                    this.carbonRecords = null;
                    this.groupingItems = null;
                    this.carbonOptions.segregateBy = newSegregateBy;
                }
                const newTabKey = this.tabKey;
                const preferences = await getUserPreferences();
                preferences.costExplorerTab = newTabKey;
                this.starred = preferences.starredCostExplorerTab === preferences.costExplorerTab;
                await setUserPreferences(preferences);
            },
            immediate: true,
            deep: true,
        },
        async starred(isStarred) {
            const preferences = await getUserPreferences();
            preferences.starredCostExplorerTab = isStarred
                ? this.tabKey
                : this.tabKey === preferences.starredCostExplorerTab
                ? null
                : preferences.starredCostExplorerTab;
            await setUserPreferences(preferences);
        },
        tagKey: {
            handler(newTagKey, oldTagKey) {
                if (!oldTagKey) return;
                this.getCarbonRecords(this.carbonOptions);
            },
        },
    },
    computed: {
        tabKey() {
            const newSegregateBy = getSegregateByFromRoute(this.$route);
            return newSegregateBy === 'tags'
                ? `tagkeys/${encodeURIComponent(this.tagKey)}`
                : newSegregateBy.toLowerCase();
        },
        chartTitle() {
            return calculateOptionsDescription(this.carbonOptions, (...params) => this.$t(...params));
        },
        currentHeaderName() {
            return this.headerName(this.carbonOptions.segregateBy);
        },
        tabs() {
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}` : '';
            const tabs = [
                {
                    title: this.$t('emissionsExplorer.tabs.products'),
                    key: 'products',
                    to: `${prefix}/emissions/explore/products`,
                    icon: 'shopping_cart',
                },
                {
                    title: this.$t('emissionsExplorer.tabs.resources'),
                    key: 'resources',
                    to: `${prefix}/emissions/explore/services`,
                    icon: 'memory',
                },
                {
                    title: this.$t('emissionsExplorer.tabs.subscriptions'),
                    key: 'subscriptions',
                    to: `${prefix}/emissions/explore/subscriptions`,
                    icon: 'cloud_queue',
                },
                {
                    title: this.$t('emissionsExplorer.tabs.cloudaccounts'),
                    key: 'cloudaccounts',
                    to: `${prefix}/emissions/explore/cloudaccounts`,
                    icon: 'cloud_download',
                },
            ];
            if (this.tenantStore.tagKey1) {
                tabs.push({
                    title: this.tenantStore.tagKey1,
                    key: this.tenantStore.tagKey1,
                    to: `${prefix}/emissions/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey1)}`,
                    icon: 'sell',
                });
            }
            if (this.tenantStore.tagKey2) {
                tabs.push({
                    title: this.tenantStore.tagKey2,
                    key: this.tenantStore.tagKey2,
                    to: `${prefix}/emissions/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey2)}`,
                    icon: 'sell',
                });
            }
            if (this.tenantStore.tagKey3) {
                tabs.push({
                    title: this.tenantStore.tagKey3,
                    key: this.tenantStore.tagKey3,
                    to: `${prefix}/emissions/explore/tagkeys/${encodeURIComponent(this.tenantStore.tagKey3)}`,
                    icon: 'sell',
                });
            }
            return tabs;
        },
        ...mapStores(useTenantStore),
    },
    methods: {
        handleDialogChange(isOpen) {
            const prefix = this.$route.params.baseTenantId ? `/${this.$route.params.baseTenantId}` : '';
            if (isOpen) return;
            if (this.carbonOptions.segregateBy === PluralEntityType.tags) {
                this.$router.push(`${prefix}/emissions/explore/tagkeys/${encodeURIComponent(this.tagKey)}`);
            } else {
                this.$router.push(`/emissions/explore/${this.carbonOptions.segregateBy}`);
            }
            this.$title = this.$t('emissionsExplorer.title');
        },
        headerName(by) {
            return this.$t('emissionsExplorer.headers.' + by);
        },
        getCarbonRecords: waitFor('gettingCarbonRecords', async function getCarbonRecords(options: SpendUsageOptions) {
            const params = convertSpendOptionsToQuery(options);
            if (!params) return;
            const segregateBy = this.carbonOptions.segregateBy as PluralEntityType;
            if (segregateBy === PluralEntityType.tags) {
                params.tagKeyValue = this.tagKey ? `${this.tagKey}:` : null;
            }
            const [carbonResponse, projectionResponse] = await Promise.all([
                getCO2e(segregateBy, params),
                getForecastCO2e(params, options.dateRangeKey),
            ]);
            const projectionsDataset = convertProjectionsResponseToDataset(projectionResponse);
            const [usageDataset, datasetIndexes] = convertCO2eResponseToDatasets(carbonResponse);
            this.newChartData = {
                labels: [...usageDataset.labels, ...projectionsDataset.labels],
                datasets: [...usageDataset.datasets, ...projectionsDataset.datasets],
            };
            this.datasetIndexes = datasetIndexes;
            this.groupingItems = convertToItemRecords(params, carbonResponse);
        }),
        handleDatasetClicked({ index, isOther }) {
            if (isOther) {
                this.carbonOptions.topXResults = this.carbonOptions.topXResults + 10;
            } else if (this.carbonOptions.segregateBy === 'tags') {
                const datasetGroupId = this.datasetIndexes[index];
                const tag = `${encodeURIComponent(this.tagKey)}:${encodeURIComponent(datasetGroupId)}`;
                this.$router.push(`/emissions/explore/tagkeys/${encodeURIComponent(this.tagKey)}/tag/${tag}/carbon`);
            } else {
                const datasetGroupId = this.datasetIndexes[index];
                this.$router.push(`/emissions/explore/${this.carbonOptions.segregateBy}/${datasetGroupId}/carbon`);
            }
        },
    },
});
