import { defineStore } from 'pinia';
import Api from '@/lib/ApiV2';
import { SpendUsageOptions } from '@/components/Domain.Usage/SpendUsageOptionsToolbarForm.vue';
import { convertToViewModel, ISearchEntityViewModel } from "@/components/Common/EntitySearchAutocomplete.vue";
import {
    EntityType,
    ISearchEntity,
    PluralEntityType,
} from "@/models";
import Config from '@/lib/Config';

export const useFilterStore = defineStore({
    id: 'filter',
    state() {
        return {
            options: getInitialFilter()
        }
    },
    actions: {
        setFilter(data: Partial<SpendUsageOptions>) {
            this.options = { ...this.options, ...data };
        },
        async fetchSelectedSearchEntity() {
            if(this.options.entities && this.options.entities.length > 0) {
                return this.options.entities;
            }

            let qs = ''
            const searchType = [];
            if (this.options.subscriptionIds?.length) {
                return [await fetchSubscriptionById(this.options.subscriptionIds[0])];
            } else if (this.options.cloudAccountId) {
                return [await fetchCloudAccountById(this.options.cloudAccountId)];
            } else if (this.options.serviceId) {
                return [await fetchServiceById(this.options.serviceId)];
            } else if (this.options.tagKeyValue) {
                searchType.push(EntityType.tag);
                qs = this.options.tagKeyValue
            } else if (this.options.regionName) {
                searchType.push(EntityType.region);
                qs = this.options.regionName
            } else if (this.options.providerType) {
                searchType.push(EntityType.cloudProvider);
                qs = this.options.providerType
            }
            else if (this.options.geographyName) {
                searchType.push(EntityType.geography);
                qs = this.options.geographyName;
            }
            else if (this.options.productCategory) {
                searchType.push(EntityType.productCategory);
                qs = this.options.productCategory;
            }
            else if (this.options.productId) {
                searchType.push(EntityType.product);
                qs = this.options.productId;
            }
            else if (this.options.savedViewId) {
                searchType.push(EntityType.customView);
                qs = this.options.savedViewId;
            }
            if (qs != '') {
                const r = await queryEntities(qs, searchType, 0, 30);
                return r;
            }
            return null;
        },
        reset() {
            this.setFilter(getInitialFilter());
        }
    },
    persist: {
        afterRestore(ctx) {
            if (ctx.store.options.fromDate)
                ctx.store.options.fromDate = new Date(ctx.store.options.fromDate);
            if (ctx.store.options.toDate)
                ctx.store.options.toDate = new Date(ctx.store.options.toDate);
        }
    }
})

const queryEntities = async (queryString, types, page, pageSize) => {
    const searchParams = new URLSearchParams();
    const searchQuery = `${queryString || ''}`;
    searchParams.append('q', searchQuery);
    if (typeof types !== 'undefined' && types.join) {
        searchParams.append('types', types.join());
    }
    if (typeof page !== 'undefined') {
        searchParams.append('page', page);
    }
    if (typeof pageSize !== 'undefined') {
        searchParams.append('pageSize', pageSize);
    }
    const searchItems = await Api.http
        .get(`/api/search?${searchParams.toString()}`)
        .then((r) => r.data.map(convertToViewModel));

    return searchItems;
}

const fetchSubscriptionById = async (id: string): Promise<ISearchEntityViewModel> => {
    const subscription = await Api.http
        .get(`/api/metadata/subscriptions/${id}`)
        .then(r => r.data);

    const entity: ISearchEntity = {
        id: id,
        name: subscription.subscriptionName,
        type: EntityType.subscription,
        description: '',
        provider: subscription.cloudProviderType,
        entityIndex: 1
    }
    return convertToViewModel(entity);
}

const fetchCloudAccountById = async (id: string): Promise<ISearchEntityViewModel> => {
    const cloudAccount = await Api.http
        .get(`/api/metadata/cloudAccounts/${id}`)
        .then(r => r.data);

    const entity: ISearchEntity = {
        id: id,
        name: cloudAccount.name,
        type: EntityType.cloudAccount,
        description: cloudAccount.typeDescription,
        provider: cloudAccount.providerType,
        entityIndex: 1
    }
    return convertToViewModel(entity);
}

const fetchServiceById = async (id: string): Promise<ISearchEntityViewModel> => {
    const service = await Api.http
        .get(`/api/metadata/services/${id}`)
        .then(r => r.data);

    const entity: ISearchEntity = {
        id: id,
        name: service.serviceName,
        type: EntityType.cloudAccount,
        description: '',
        provider: service.cloudProviderType,
        entityIndex: 1
    }
    return convertToViewModel(entity);
}

const getInitialFilter = (): SpendUsageOptions => {
    const initialFilter = <SpendUsageOptions>{
        dateRangeKey: Config.isFeatureEnabled('UsageDefault:Date:30d') ? '30d' : '60d',
        granularity: 'Daily',
        topXResults: 25,
        segregateBy: PluralEntityType.products,
        toDate: null,
        fromDate: null,
        cloudAccountId: null,
        subscriptionIds: null,
        serviceId: null,
        productId: null,
        providerType: null,
        regionName: null,
        geographyName: null,
        tagKeyValue: null,
        productCategory: null,
        entities: null
    };
    return initialFilter;
}
